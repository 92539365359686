import React from 'react'
//import Link from 'gatsby-link'
//import werkstattBild from '../../images/werkstatt01.jpg'
import geigenBild from '../../images/geigen.jpg'
import Layout from '../Layout/Layout.js'

import './page.module.css'


const Page = (props) => (
  <Layout>
    <div styleName={'pageContainer'}>
      <div styleName={'pageTitle'}>
        <img src={geigenBild} alt='violins'/>
        <div styleName={'siteTitleContainer'}>
          <div styleName={'fontTop'}>
            {props.title}
          </div>
        </div>
      </div>

      <div styleName={'pageContent'}>
        {props.renderCallback()}
      </div>
    </div>
  </Layout>
)

export default Page
