import React from 'react'
import Page from '../components/Page/Page'

const NotFoundPage = () => (
    <Page
      title={'404'}
      renderCallback={() => (
        <div>
          <h1>Seite nicht gefunden</h1>
          <p>Die Seite die Sie aufrufen wollen existiert nicht. Bitte überprüfen sie die eingegebene URL.</p>
          <h1>Path not found</h1>
          <p>The site you tried to access does not exist. Please check the requested URL.</p>
        </div>
      )}
    />
)

export default NotFoundPage
